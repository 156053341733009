// import { firestoreAction } from "vuexfire";
// import { db } from "@/db";

const state = {
	cartProducts: []
};
const getters = {
	cartProducts: (state) => state.cartProducts,
	totalPrice: (getters) => {
		return getters.cartProducts.reduce((total, product) => total + product.price * product.quantity, 0);
	}
};
const mutations = {
	addProductToCart(state, product) {
		product.quantity = 1;
		state.cartProducts.push(product);
		state.totalPrice = state.totalPrice + product.price;
		// console.log(product)
	},
	removeCartItem(state, index) {
		state.cartProducts.splice(index, 1);
	},
	addQuantity(state, index) {
		const updateQty = state.cartProducts[index];
		updateQty.quantity++;
		state.cartProducts.splice(index, 1, updateQty);
	},
	removeQuantity(state, index) {
		const updateQty = state.cartProducts[index];

		if (updateQty.quantity > 1) {
			updateQty.quantity--;
			state.cartProducts.splice(index, 1, updateQty);
		} else {
			// console.log('At least 1 Qty required');
		}
	}
};
const actions = {
	getSelectedProduct(context, product) {
		//console.log(product)
		context.commit('addProductToCart', product);
	},
	removeCartItem(context, product) {
		// console.log(product)
		const index = context.getters.cartProducts.indexOf(product);
		context.commit('removeCartItem', index);
	},
	addQuantity(context, product) {
		const index = context.getters.cartProducts.indexOf(product);
		return context.commit('addQuantity', index);
	},
	removeQuantity(context, product) {
		const index = context.getters.cartProducts.indexOf(product);
		return context.commit('removeQuantity', index);
	}
};

export default {
	state,
	getters,
	mutations,
	actions
};
