<template>
  <v-app>
    <Navbar />
    <v-content class="mt-4">
      <router-view></router-view>
    </v-content>
    <Footer />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Navbar from "./partials/Navbar.vue";
import Footer from "./partials/Footer.vue";

export default {
  name: "home",
  components: {
    Navbar,
    Footer
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "allProducts",
      "allProductCategories",
      "services",
      "getHero",
      "getContacts",
      "getAboutData",
      "gallery"
    ])
  },
  methods: {
    ...mapActions(["bind"]),
    fetchAllData() {
      if (this.getHero == null) {
        // console.log("Messages");
        this.bind({
          collection: "pages",
          doc: "home",
          data: "hero"
        });
      }
      if (!Object.keys(this.getContacts).length) {
        // console.log("Orders");
        this.bind({
          collection: "pages",
          doc: "contacts",
          data: "contacts"
        });
      }
      if (this.getAboutData == null) {
        // console.log("Users");

        this.bind({
          collection: "pages",
          doc: "about",
          data: "about"
        });
      }
      if (Object.keys(this.gallery).length === 0) {
        // console.log("Users");
        this.bind({
          collection: "pages",
          doc: "gallery",
          data: "gallery"
        });
      }
      if (this.services == null) {
        // console.log("Users");
        this.bind({
          collection: "pages",
          doc: "services",
          data: "services"
        });
      }
      if (!this.allProducts.length) {
        // console.log("Products");

        this.bind({
          collection: "products",
          data: "products"
        });
      }
      if (!this.allProductCategories.length) {
        // console.log("Product Categories");

        this.bind({
          collection: "productCategories",
          data: "productCategories"
        });
      }
    }
  },
  created() {
    this.fetchAllData();
  }
};
</script>
