<template>
  <div class="text-center">
    <v-menu v-model="menu" bottom :close-on-content-click="false" :nudge-width="200" offset-y>
      <template v-slot:activator="{ on }">
        <v-avatar v-on="on">
          <img v-if="currentUser.photoURL" :src="currentUser.photoURL" alt="user" />
          <v-icon v-else large>mdi-account-circle</v-icon>
        </v-avatar>
        <!-- <v-btn color="indigo" outlined dark v-on="on">Account</v-btn> -->
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img v-if="currentUser.photoURL" :src="currentUser.photoURL" alt="user" />
              <v-icon v-else>mdi-account-circle</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="currentUser.name"></v-list-item-title>
              <v-list-item-subtitle v-text="currentUser.email">Founder of Vuetify.js</v-list-item-subtitle>
            </v-list-item-content>

            <!-- <v-list-item-action>
              <v-btn icon>
                <v-icon>mdi-account-edit</v-icon>
              </v-btn>
            </v-list-item-action>-->
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item-group>
            <v-list-item to="/account/orders">
              <v-list-item-icon>
                <v-icon>mdi-file-document-box</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>My Orders</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-map-marker-radius</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Address</v-list-item-title>
              </v-list-item-content>
            </v-list-item>-->
            <v-list-item to="/logout">
              <v-list-item-icon>
                <v-icon>mdi-power</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    currentUser: Object
  },
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true
  })
};
</script>
