<template>
  <footer>
    <v-container class="px-0" v-if="!$vuetify.breakpoint.smAndDown" fluid>
      <v-container class="d-flex">
        <v-card-text class="text-left grey--text">
          <div>Copyright ©2020 All rights reserved</div>
        </v-card-text>

        <v-card-text class="text-right align-content-end">
          <v-icon class="grey--text">share</v-icon>
        </v-card-text>
      </v-container>
    </v-container>
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
