import Vue from 'vue'; // Import Vue
import App from './App.vue'; // Import App Template
import router from './router'; // Import Vue Router
import store from './store/index'; // Import Vuex
import vuetify from './plugins/vuetify';
import VueHtmlToPaper from './plugins/vue-html-to-paper';
import VeeValidate from 'vee-validate';
import moment from 'moment';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

import VueCurrencyFilter from 'vue-currency-filter';
Vue.use(VueCurrencyFilter, {
	symbol: 'SR',
	thousandsSeparator: ',',
	fractionCount: 2,
	fractionSeparator: '.',
	symbolPosition: 'front',
	symbolSpacing: true
});

Vue.use(VeeValidate);

Vue.config.productionTip = false;

// For Date
Vue.filter('formatDate', function(value) {
	if (value) {
		return moment(Number(value)).format('DD/MM/YYYY hh:mm');
	}
});

//Notification
import Snackbar from '@/components/base/Snackbar.vue';
Vue.component('Snackbar', Snackbar);

// Calling Vuex Action Bind CurrentUser
store.dispatch('bindCurrentUser');
//Router Guard
import './guard';

new Vue({
	router,
	store,
	vuetify,
	VueHtmlToPaper,
	render: (h) => h(App)
}).$mount('#app');
