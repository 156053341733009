<template>
  <div>
    <v-container class="pa-0 my-0">
      <v-app-bar color="#fafafa" light flat>
        <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title class="pl-3 pt-4">
          <v-avatar height="100%" size="78%" class="d-flex justify-start" tile>
            <Logo />
          </v-avatar>
          <v-img max-height="70"></v-img>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="hidden-sm-and-down">
          <v-btn
            class="grey--text"
            active-class="primary--text"
            v-for="routerLink in routerLinks"
            :key="routerLink.name"
            :to="routerLink.route"
            text
          >{{ routerLink.name }}</v-btn>
          <v-menu open-on-hover bottom offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                to="/product-categories"
                class="grey--text"
                active-class="primary--text"
                text
              >Products</v-btn>
            </template>

            <v-list dense>
              <v-subheader>Catagories</v-subheader>
              <v-list-item
                :to="'/products/' + item.id"
                v-for="(item, i) in allProductCategories"
                :key="i"
              >
                <!-- <v-list-item-avatar v-if="item.image">
                  <v-img :src="item.image"></v-img>
                </v-list-item-avatar>-->
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn class="grey--text" active-class="primary--text" to="/gallery" text>Gallery</v-btn>
          <v-btn class="grey--text" active-class="primary--text" to="/contacts" text>Contacts</v-btn>
        </div>
        <v-tooltip color="primary" bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              v-if="cartProducts.length"
              class="grey--text"
              active-class="primary--text"
              to="/cart"
              text
              large
            >
              <v-badge button overlap>
                <template v-slot:badge>{{ cartProducts.length }}</template>
                <v-icon>mdi-cart</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>Your Cart</span>
        </v-tooltip>

        <template v-if="!currentUser">
          <v-tooltip color="primary" bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="grey--text" to="/login" icon>
                <v-icon>mdi-login</v-icon>
              </v-btn>
            </template>
            <span>Login</span>
          </v-tooltip>
        </template>

        <template v-if="currentUser">
          <v-btn
            v-if="currentUser.role == 'admin'"
            class="primary--text hidden-sm-and-down"
            active-class="primary--text"
            to="/admin"
            outlined
            text
            large
          >Admin Area</v-btn>
          <UserDropDownWidget v-if="currentUser.role == 'user'" :currentUser="currentUser" />
        </template>
      </v-app-bar>
    </v-container>

    <v-navigation-drawer class="hidden-md-and-up" v-model="drawer" temporary fixed app>
      <v-divider></v-divider>
      <v-list-item v-if="currentUser">
        <v-list-item-avatar>
          <img :src="currentUser.photoURL" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="title">Hi, {{ currentUser.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ currentUser.role }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="router in routerLinks" :key="router.name" :to="router.route" link>
          <v-list-item-icon>
            <v-icon>{{ router.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ router.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group no-action prepend-icon="mdi-hook" value="true">
          <template v-slot:activator>
            <v-list-item-title>Products</v-list-item-title>
          </template>
          <v-subheader @click="$router.replace('/product-categories')">Product Categories</v-subheader>
          <v-divider></v-divider>
          <v-list-item
            v-for="(item, i) in allProductCategories"
            :key="i"
            :to="'/products/'+ item.id"
            link
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item to="/gallery" link>
          <v-list-item-icon>
            <v-icon>mdi-image-frame</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Gallery</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/contacts" link>
          <v-list-item-icon>
            <v-icon>mdi-contacts</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Contacts</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <template v-if="currentUser">
          <v-list-item v-if="currentUser.role == 'admin'" to="admin" link>
            <v-list-item-icon>
              <v-icon>mdi-lock</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Admin Area</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UserDropDownWidget from "./UserDropDownWidget";
import Logo from "@/components/base/Logo.vue";
export default {
  name: "Navbar",
  components: {
    UserDropDownWidget,
    Logo,
  },
  data() {
    return {
      routerLinks: [
        {
          icon: "mdi-home",
          name: "Home",
          route: "/",
        },
        {
          icon: "mdi-lead-pencil",
          name: "About",
          route: "/about",
        },
        {
          icon: "mdi-boom-gate",
          name: "Services",
          route: "/services",
        },
      ],
      drawer: false,
    };
  },
  computed: {
    ...mapGetters(["cartProducts", "currentUser", "allProductCategories"]),
  },
  methods: {
    ...mapActions(["bind"]),
    fetchCategory() {
      if (this.allProductCategories.length === 0) {
        this.bind({
          collection: "productCategories",
          data: "productCategories",
        });
      }
    },
  },
  created() {
    this.fetchCategory();
  },
};
</script>

<style></style>
