const state = {
  isActive: false
};
const getters = {
  isActive: state => state.isActive
};
const mutations = {
  asideToggler(state) {
    state.isActive = !state.isActive;
  }
};
const actions = {
  asideToggler({ commit }) {
    commit("asideToggler");
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
