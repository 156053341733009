<template>
  <v-app>
    <Navbar />
    <v-content>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-content>
    <Footer />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Navbar from "./includes/Navbar.vue";
import Footer from "./includes/Footer.vue";
export default {
  components: {
    Navbar,
    Footer
  },
  computed: {
    ...mapGetters([
      "getMessages",
      "getOrderList",
      "allUsers",
      "allProducts",
      "allProductCategories"
    ])
  },
  methods: {
    ...mapActions(["bind"]),
    fetchAllData() {
      if (!this.getMessages.length) {
        // console.log("Messages");
        this.bind({
          collection: "messages",
          data: "messages"
        });
      }
      if (!this.getOrderList.length) {
        // console.log("Orders");
        this.bind({
          collection: "orders",
          data: "orders"
        });
      }
      if (!this.allUsers.length) {
        // console.log("Users");

        this.bind({
          collection: "users",
          data: "users"
        });
      }
      if (!this.allProducts.length) {
        // console.log("Products");

        this.bind({
          collection: "products",
          data: "products"
        });
      }
      if (!this.allProductCategories.length) {
        // console.log("Product Categories");

        this.bind({
          collection: "productCategories",
          data: "productCategories"
        });
      }
    }
  },
  created() {
    this.fetchAllData();
  }
};
</script>

