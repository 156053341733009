// import store from "./store/index"; // Import Vuex
import firebase from 'firebase';
import router from './router';
import { db } from '@/db';

router.beforeEach((to, from, next) => {
	//store.dispatch('bindCurrentUser')
	// console.log(store.getters.currentUser)
	//This will not protect Child Routers
	//if (!to.meta.requiresAuth) {
	// This will protect child Routers
	if (!to.matched.some((record) => record.meta.requiresAuth)) {
		//route is public, don't check for authentication
		next();
	} else {
		//route is protected, if authenticated, proceed. Else, login
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				const userId = user.uid;
				const currentUser = db.collection('users').doc(userId);
				currentUser
					.get()
					.then((role) => {
						if (!role.exists) {
							// console.log('No such document!');
						} else {
							const userRole = role.data().role;
							// console.log(userRole);

							if (user && userRole == 'user' && to.matched.some((record) => record.meta.user)) {
								localStorage.setItem('authenticated', true);
								localStorage.setItem('userRole', 'user');
								next();
							} else if (
								user &&
								userRole == 'admin' &&
								to.matched.some((record) => record.meta.admin || record.meta.user)
							) {
								localStorage.setItem('authenticated', true);
								localStorage.setItem('userRole', 'admin');
								next();
							} else {
								// console.log('Permission denied');
								localStorage.setItem('authenticated', false);
								localStorage.setItem('userRole', 'null');
								router.replace('/login');
							}
						}
					})
					.catch((err) => {
						// console.log('Error getting document', err);
					});
			} else {
				// console.log('Please Login');
				localStorage.setItem('authenticated', false);
				localStorage.setItem('userRole', 'null');
				router.replace('/login');
			}
		});
	}
});
