import firebase from 'firebase';
// Initialize Firebase
var firebaseConfig = {
	apiKey: 'AIzaSyD4khANRsyWQNpzKZ4RI4oMcFoSgNrlq4s',
	authDomain: 'rigcare-b4acc.firebaseapp.com',
	databaseURL: 'https://rigcare-b4acc.firebaseio.com',
	projectId: 'rigcare-b4acc',
	storageBucket: 'rigcare-b4acc.appspot.com',
	messagingSenderId: '437416382747',
	appId: '1:437416382747:web:e5700d734a58b4a98c83be'
};
// Get a Firestore instance
export const db = firebase.initializeApp(firebaseConfig).firestore();
