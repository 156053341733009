<template>
  <v-snackbar
    v-model="snackbar.isActive"
    :top="snackbar.y === 'top'"
    :bottom="snackbar.y === 'bottom'"
    :left="snackbar.x === 'left'"
    :right="snackbar.x === 'right'"
    :color="snackbar.color"
    :multi-line="snackbar.mode === 'multi-line'"
    :vertical="snackbar.mode === 'vertical'"
    :timeout="snackbar.timeout"
  >
    {{ snackbar.text }}
    <v-btn dark text @click="snackbar.isActive = false">Close</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    snackbar: Object
  }
};
</script>

<style></style>
