<template>
  <section>
    <v-navigation-drawer app v-model="drawer">
      <Logo width="80%" class="align-center px-4 pt-3" />

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in routerLinks" :key="item.name" exact :to="item.route" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar flat app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-btn to="/" outlined color="teal">Home</v-btn>
      <template>
        <v-tooltip color="primary" bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="grey--text" to="/logout" icon>
              <v-icon>mdi-power</v-icon>
            </v-btn>
          </template>
          <span>Logout</span>
        </v-tooltip>
      </template>
    </v-app-bar>
  </section>
</template>

<script>
import Logo from "@/components/base/Logo.vue";

export default {
  components: {
    Logo
  },
  data() {
    return {
      drawer: true,

      routerLinks: [
        {
          icon: "dashboard",
          name: "Dashboard",
          route: "/admin/"
        },
        {
          icon: "assignment",
          name: "Orders",
          route: "/admin/orders"
        },
        {
          icon: "event_note",
          name: "Products",
          route: "/admin/products"
        },
        {
          icon: "message",
          name: "Messages",
          route: "/admin/messages"
        },
        {
          icon: "supervisor_account",
          name: "Users",
          route: "/admin/users"
        }
      ]
    };
  }
};
</script>

<style></style>
