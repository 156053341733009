<template>
  <v-footer app inset class="grey lighten-3">
    <v-row class="mx-4">
      <v-col class="text-left grey--text" cols="12">
        &copy; 2019
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {};
</script>

<style></style>
