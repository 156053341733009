import Vue from 'vue';
import Router from 'vue-router';
import Client from './clients/Index.vue';
import Admin from './admin/Index.vue';

Vue.use(Router);

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			component: Client,
			children: [
				{
					path: '/',
					name: 'home',
					component: () => import('./clients/home/Home.vue')
				},
				{
					path: '/about',
					name: 'about',
					component: () => import('./clients/abouts/About.vue')
				},
				{
					path: '/services',
					name: 'services',
					component: () => import('./clients/services/Services.vue')
				},
				{
					path: '/product-categories/',
					name: 'product categories',
					component: () => import('./clients/products/ProductCategories.vue')
				},
				{
					path: '/products/:id',
					name: 'products',
					component: () => import('./clients/products/Products.vue')
				},
				{
					path: '/products/category/:slug',
					name: 'productDetails',
					component: () => import('./clients/products/components/ProductDetails.vue')
				},
				{
					path: '/gallery',
					name: 'gallery',
					component: () => import('./clients/gallery/Gallery.vue')
				},
				{
					path: '/contacts',
					name: 'contacts',
					component: () => import('./clients/contacts/Contacts.vue')
				},
				{
					path: '/account/orders',
					name: 'UserOrders',
					meta: { requiresAuth: true, user: true, admin: true },
					component: () => import('./clients/user/components/UserOrders.vue')
				},
				{
					path: '/account/orders/:id',
					name: 'UserOrderDetails',
					meta: { requiresAuth: true, user: true, admin: true },
					component: () => import('./clients/user/components/UserOrderDetails.vue')
				},
				{
					path: '/cart',
					name: 'cart',
					component: () => import('./clients/cart/Cart.vue')
				},
				{
					path: '/checkout',
					name: 'checkout',
					meta: { requiresAuth: true, user: true, admin: true },
					component: () => import('./clients/cart/Checkout.vue')
				},
				{
					path: '/login',
					name: 'login',
					component: () => import('./clients/auth/Login.vue')
				},
				{
					path: '/logout',
					name: 'logout',
					component: () => import('./clients/auth/Logout.vue')
				}
			]
		},
		{
			path: '/admin',
			component: Admin,
			meta: { requiresAuth: true, admin: true },
			children: [
				{
					path: '/',
					name: 'Dashboard',
					component: () => import('./admin/dashboard/Dashboard.vue')
				},
				{
					path: '/admin/orders',
					name: 'Orders',
					component: () => import('./admin/orders/Orders.vue')
				},
				{
					path: '/admin/orders/:id',
					name: 'orderDetails',
					component: () => import('./admin/orders/components/OrderDetails.vue')
				},
				{
					path: '/admin/products',
					name: 'Products',
					component: () => import('./admin/products/Products.vue')
				},
				{
					path: '/admin/products/add',
					name: 'Add Product',
					component: () => import('./admin/products/ProductAdd.vue')
				},
				{
					path: '/admin/products/:id',
					name: 'Add Product',
					component: () => import('./admin/products/ProductUpdate.vue')
				},
				{
					path: '/admin/products/category/list',
					name: 'Products Category List',
					component: () => import('./admin/products/category/ProductCategoryList.vue')
				},
				{
					path: '/admin/products/category/add',
					name: 'Add Products Category',
					component: () => import('./admin/products/category/ProductCategoryAdd.vue')
				},
				{
					path: '/admin/products/category/:id',
					name: 'Update Products Category',
					component: () => import('./admin/products/category/ProductCategoryUpdate.vue')
				},
				{
					path: '/admin/users',
					name: 'Users',
					component: () => import('./admin/users/Users.vue')
				},
				{
					path: '/admin/messages',
					name: 'Messages',
					component: () => import('./admin/messages/Messages.vue')
				}
			]
		},
		{
			path: '*',
			name: '404',
			component: () => import('./404.vue')
		}
	]
});
