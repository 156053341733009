import { firestoreAction } from 'vuexfire';
import { db } from '@/db';
const state = {
	// Client
	hero: null,
	about: null,
	services: null,
	gallery: {},
	contacts: {},

	// Admin
	orders: [],
	users: [],
	messages: [],

	// Products
	products: [],
	productCategories: [],
	currentProduct: []
};
const getters = {
	// Client
	getHero: (state) => state.hero,
	services: (state) => state.services,
	gallery: (state) => state.gallery,
	getAboutData: (state) => state.about,
	getContacts: (state) => state.contacts,

	// Admin
	getOrderList: (state) => state.orders,
	allUsers: (state) => state.users,
	getMessages: (state) => state.messages,

	// Products
	allProducts: (state) => state.products,
	allProductCategories: (state) => state.productCategories,
	getCurrentProduct: (state) => state.currentProduct,
	getProductBySlug: (state) => (slug) => {
		return state.products.find((product) => product.slug === slug);
	},
	getProductByCategory: (state) => (category) => {
		return state.products.filter((product) => product.category == category);
	}
};
const mutations = {};
const actions = {
	// Update data to firebase
	update: firestoreAction((context, payload) => {
		const collection = payload.collection;
		const doc = payload.doc;
		const data = payload.data;
		return db.collection(collection).doc(doc).update(data);
	}),
	// Bind data from firebase
	bind: firestoreAction((context, payload) => {
		// console.log(payload);
		const collection = payload.collection;
		const doc = payload.doc;
		const data = payload.data;
		if (doc === undefined) {
			// return the promise returned by `bindFirestoreRef`
			return context.bindFirestoreRef(data, db.collection(collection));
		} else {
			return context.bindFirestoreRef(data, db.collection(collection).doc(doc));
		}
	}),
	// Add data to firebase
	add: firestoreAction((context, payload) => {
		const collection = payload.collection;
		const doc = payload.doc;
		const data = payload.data;

		if (doc === undefined) {
			// return the promise so we can await the write
			return db.collection(collection).add(data);
		} else {
			return db.collection(collection).doc(doc).add(data);
		}
	}),
	// Delete data from firebase
	delete: firestoreAction((context, payload) => {
		const collection = payload.collection;
		const doc = payload.doc;
		return db.collection(collection).doc(doc).delete();
	})
};

export default {
	state,
	getters,
	mutations,
	actions
};
