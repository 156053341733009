// import firebase from 'firebase'
import { firestoreAction } from 'vuexfire';
import firebase from 'firebase';
import { db } from '@/db';

const state = {
	currentUser: ''
};
const getters = {
	currentUser: (state) => state.currentUser,
	currentUserRole: (state) => state.currentUser.role
};
const mutations = {};
const actions = {
	bindCurrentUser: firestoreAction(({ state, bindFirestoreRef }) => {
		// return the promise returned by `bindFirestoreRef`
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				const userId = user.uid;
				return bindFirestoreRef('currentUser', db.collection('users').doc(userId));
			} else {
				state.currentUser = '';
			}
		});
	})
};

export default {
	state,
	getters,
	mutations,
	actions
};
