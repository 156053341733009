import Vue from 'vue';
import Vuex from 'vuex';
import { vuexfireMutations } from 'vuexfire';
import base from './modules/base';
import crude from './modules/firestore/crude';
import cart from './modules/cart';
import currentUser from './modules/currentUser';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [
		createPersistedState({
			paths: [ 'cart' ]
		})
	],

	modules: {
		crude,
		cart,
		currentUser,
		base
	},
	state: {},
	mutations: {
		// other mutations
		...vuexfireMutations
	},
	actions: {}
});
